<template>
  <div class="intelligent-paper-layout">
    <Breadcrumb>
      <BreadcrumbItem>考试管理</BreadcrumbItem>
      <BreadcrumbItem>试卷库</BreadcrumbItem>
      <BreadcrumbItem>智能出卷</BreadcrumbItem>
    </Breadcrumb>
    <div class="wrapper">
      <div class="left">
        <InputWrapper label="题库" :label-width="70">
          <Select v-model="selectedQuestionBankIds" multiple>
            <Option v-for="option in questionBanks" :value="option.questionBankId" :key="option.questionBankId">{{ option.name }}</Option>
          </Select>
        </InputWrapper>
        <InputWrapper label="知识点" :label-width="70">
          <Select v-model="selectedKnowledgePointIds" multiple>
            <Option v-for="knowledge in allKnowledgeList" :value="knowledge.id" :key="knowledge.id">{{ knowledge.name }}</Option>
          </Select>
        </InputWrapper>
        <InputWrapper v-if="questionAvailableNum" label="题目类型" :label-width="70">
          <CheckboxGroup v-model="questionTypes">
            <Checkbox :label="1">
              <span>单选题</span>
            </Checkbox>
            <Checkbox :label="2">
              <span>多选题</span>
            </Checkbox>
            <Checkbox :label="0">
              <span>判断题</span>
            </Checkbox>
            <Checkbox :label="3">
              <span>填空题</span>
            </Checkbox>
            <Checkbox :label="4">
              <span>简答题</span>
            </Checkbox>
          </CheckboxGroup>
        </InputWrapper>
        <div class="footer">
          <Button type="primary" :disabled="questionTypes.length === 0" @click="handleGenerate" :loading="isGenerating">生成试卷</Button>
        </div>
      </div>
      <div class="right">
        <EmptyStatus v-if="questionTypes.length === 0" hint="请在左侧选择题目类型" />
        <div v-else class="question-rule-wrapper">
          <div class="question-rule-item" :class="{ 'is-odd': index % 2 !== 0 }" v-for="(type, index) in questionTypes" :key="type">
            <div class="question-type-label">
              {{ _getQuestionTypeByValue(type).label }}
            </div>
            <div v-if="questionTypeArrange[type]" class="simple">
              简单
              <InputNumber
                :precision="0"
                :min="0"
                :max="questionAvailableNum[type][0]"
                :disabled="questionAvailableNum[type][0] === 0"
                v-model="questionTypeArrange[type]['simple']"
                size="small"
                class="level-num-input"
              />
              题 (
              <span :style="questionAvailableNum[type][0] > 0 ? 'color: green' : ''">{{ questionAvailableNum[type][0] }}可用</span>
              )
            </div>
            <div v-if="questionTypeArrange[type]" class="medium">
              中等
              <InputNumber
                :precision="0"
                :min="0"
                :max="questionAvailableNum[type][1]"
                :disabled="questionAvailableNum[type][1] === 0"
                v-model="questionTypeArrange[type]['medium']"
                size="small"
                class="level-num-input"
              />
              题 (
              <span :style="questionAvailableNum[type][1] > 0 ? 'color: green' : ''">{{ questionAvailableNum[type][1] }}可用</span>
              )
            </div>
            <div v-if="questionTypeArrange[type]" class="hard">
              困难
              <InputNumber
                :precision="0"
                :min="0"
                :max="questionAvailableNum[type][2]"
                :disabled="questionAvailableNum[type][2] === 0"
                v-model="questionTypeArrange[type]['hard']"
                size="small"
                class="level-num-input"
              />
              题 (
              <span :style="questionAvailableNum[type][2] > 0 ? 'color: green' : ''">{{ questionAvailableNum[type][2] }}可用</span>
              )
            </div>
          </div>
        </div>
        <!--        <div>{{ questionTypeArrange }}</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import InputWrapper from '@components/common/InputWrapper'
import EmptyStatus from '@components/common/EmptyStatus'
import { getQuestionTypeByValue } from '@util/questionType'
import QuestionAPI from '@api/question'
import PaperAPI from '@api/paper'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name: 'IntelligentPaper',
  components: {
    InputWrapper,
    EmptyStatus
  },
  created() {
    this.requestQuestionBanks()
    this.getAllKnowledgeList()
  },
  data() {
    return {
      questionTypes: [],
      questionTypeArrange: {},
      questionBanks: [],
      selectedQuestionBankIds: [],
      selectedKnowledgePointIds: [],
      questionAvailableNum: null,
      isGenerating: false
    }
  },
  methods: {
    ...mapActions('knowledge', ['getAllKnowledgeList']),
    ...mapMutations('question', ['setIntelligentlyGeneratePaper']),
    _getQuestionTypeByValue(value) {
      return getQuestionTypeByValue(value)
    },
    async requestQuestionBanks() {
      const { res } = await QuestionAPI.getMyQuestionBanks('', '', 0, 9999)
      this.questionBanks = res.data
    },
    async handleRequestAvailableQuestions() {
      if (this.selectedQuestionBankIds.length > 0 && this.selectedKnowledgePointIds.length > 0) {
        const { res } = await QuestionAPI.getQuestionAvailableNum(this.selectedQuestionBankIds, this.selectedKnowledgePointIds)
        const questionAvailableMap = {}
        res.forEach(item => {
          if (!questionAvailableMap[item.questionType]) {
            questionAvailableMap[item.questionType] = {}
          }
          questionAvailableMap[item.questionType][item.difficulty] = item.num
        })
        this.questionAvailableNum = questionAvailableMap
      }
    },
    async handleGenerate() {
      const difficultyMap = ['simple', 'medium', 'hard']
      const questionSections = Object.keys(this.questionTypeArrange)
        .map(type => {
          const arrange = this.questionTypeArrange[type]
          return Object.keys(arrange)
            .map(difficulty => {
              if (arrange[difficulty] !== 0) {
                return {
                  questionType: +type,
                  difficulty: difficultyMap.indexOf(difficulty),
                  num: arrange[difficulty]
                }
              }
            })
            .filter(item => item)
        })
        .reduce((pre, cur) => [...pre, ...cur], [])
      if (questionSections.length === 0) {
        this.$message.error('没有设置题目数量规则')
        return
      }
      const payload = {
        knowledgePointIds: [...this.selectedKnowledgePointIds],
        questionBankIds: [...this.selectedQuestionBankIds],
        questionSections
      }
      this.isGenerating = true
      try {
        const { res } = await PaperAPI.generatePaperIntelligently(payload)
        this.setIntelligentlyGeneratePaper(res.sectionVOS)
      } finally {
        this.isgen = false
      }
      const bankId = this.$route.query['bankId']
      this.$router.push(`/exam/paper/editor?bankId=${bankId}`)
    }
  },
  computed: {
    ...mapState('knowledge', ['allKnowledgeList'])
  },
  watch: {
    questionTypes(newVal) {
      const questionTypeArrangeCopy = { ...this.questionTypeArrange }
      newVal.forEach(type => {
        if (!questionTypeArrangeCopy[type]) {
          questionTypeArrangeCopy[type] = {
            simple: 0,
            medium: 0,
            hard: 0
          }
        }
      })
      // 清除已经被移除的键
      Object.keys(questionTypeArrangeCopy).forEach(key => {
        if (this.questionTypes.indexOf(+key) === -1) {
          delete questionTypeArrangeCopy[key]
        }
      })
      this.questionTypeArrange = questionTypeArrangeCopy
    },
    selectedQuestionBankIds() {
      this.handleRequestAvailableQuestions()
    },
    selectedKnowledgePointIds() {
      this.handleRequestAvailableQuestions()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/mixin';
.intelligent-paper-layout {
  padding: 20px;
  text-align: left;
}
.wrapper {
  margin-top: 20px;
  display: flex;
  & > .left {
    flex: 1;
    .--mixin-shadow;
    background: white;
    padding: 20px;
    .footer {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
  }
  & > .right {
    flex: 2;
    .--mixin-shadow;
    background: white;
    margin-left: 10px;
    padding: 20px;
  }
}
.question-rule-item {
  background: white;
  padding: 20px 30px;
  border: solid 1px #e3e3e3;
  display: flex;
  justify-content: space-between;
  &.is-odd {
    background: #e6e7e9;
  }
  & .level-num-input {
    width: 50px;
  }
}
</style>
