<template>
  <div class="empty-status-layout">
    <JYIcon :href="icon" class="icon" :class="{ spin: spin }" />
    <div class="empty-text">{{ hint || '没有数据' }}</div>
  </div>
</template>

<script>
import JYIcon from '@components/common/JYIcon'
export default {
  name: 'EmptyStatus',
  props: {
    hint: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      default: '#icon-web__zanwujilu'
    },
    spin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    JYIcon
  }
}
</script>

<style lang="less" scoped>
.empty-status-layout {
  min-height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.empty-image {
  width: 85px;
  height: 100px;
}
.empty-text {
  margin-top: 20px;
  color: #909399;
}
.icon {
  font-size: 80px;
  color: #909399;
}
.spin {
  animation-name: animate-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
::v-deep .operaIcon {
  width: 80px;
  height: 80px;
}
@keyframes animate-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
